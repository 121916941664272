import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import config from "./config"

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: config.defaultLanguage,
    detection: {
      order: ["path", "localStorage", "navigator"],
    },
    resources: {
      en: {
        translations: require("./en.json"),
      },
      hu: {
        translations: require("./hu.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
    supportedLngs: config.languages,
    nonExplicitSupportedLngs: true,
  })

i18next.languages = config.languages

export default i18next
